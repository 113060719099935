import React, { useState } from 'react';
import './NumberComparison.css';
import Logo from './logo.jpg'

function NumberComparison() {
  const [firstList, setFirstList] = useState('');
  const [secondList, setSecondList] = useState('');
  const [commonNumbers, setCommonNumbers] = useState([]);

  const handleFirstListChange = (e) => {
    setFirstList(e.target.value);
  };

  const handleSecondListChange = (e) => {
    setSecondList(e.target.value);
  };

  const compareLists = () => {
    const splitString = str => str.split(/[,|]/).map(Number).filter(num => !isNaN(num));
    const list1 = splitString(firstList);
    const list2 = splitString(secondList);

    const common = list1.filter(num => list2.includes(num));
    setCommonNumbers(common);
  };

  return (
    <div className="container">
      <h1>Comparação de Números</h1>
     
      <div className="input-group">
        <label>
          Primeira Lista de Números:
          <input
            type="text"
            value={firstList}
            onChange={handleFirstListChange}
          />
        </label>
      </div>
      <div className="input-group">
        <label>
          Segunda Lista de Números:
          <input
            type="text"
            value={secondList}
            onChange={handleSecondListChange}
          />
        </label>
       
      </div>
      <button onClick={compareLists}>Comparar</button>
      <div className="result">
        <h2>Números Comuns:</h2>
        {commonNumbers.length > 0 ? (
          <ul>
            {commonNumbers.map((num, index) => (
              <li key={index}>{num}</li>
            ))}
          </ul>
        ) : (
          <p>Nenhum número comum encontrado.</p>
        )}
      </div>
      <h3>Raul Cordeiro Tecnologias</h3>
      <p>Criação de aplicativos e Automação comercial</p>
      <p>
        Fone: 62 98468-7974
      </p>
      <img src={Logo} className='logo' alt='logo' />
    </div>
  );
}

export default NumberComparison;
